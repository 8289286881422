export const CampaignsPermissionsKey = 'campaigns';
export const LoyaltyPermissionsKey = 'loyalty';
export const OrdersPermissionsKey = 'orders';
export const ProductsPermissionsKey = 'products';
export const ShopsPermissionsKey = 'shops';

export const CAMPAIGNS_READ = `${CampaignsPermissionsKey}.read`;
export const CAMPAIGNS_WRITE = `${CampaignsPermissionsKey}.write`;
export const LOYALTY_READ = `${LoyaltyPermissionsKey}.read`;
export const LOYALTY_WRITE = `${LoyaltyPermissionsKey}.write`;
export const ORDERS_READ = `${OrdersPermissionsKey}.read`;
export const ORDERS_WRITE = `${OrdersPermissionsKey}.write`;
export const PRODUCTS_READ = `${ProductsPermissionsKey}.read`;
export const PRODUCTS_WRITE = `${ProductsPermissionsKey}.write`;
export const SHOPS_READ = `${ShopsPermissionsKey}.read`;
export const SHOPS_WRITE = `${ShopsPermissionsKey}.write`;

export const HasAllPermissions = (userPermissions: Array<string>, requiredPermissions: Array<string>): boolean => {
  if (!userPermissions) {
    return false;
  }

  return requiredPermissions.every((x) => userPermissions.includes(x));
};
