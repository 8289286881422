import {
  B2BPrepaidCampaignCreateRequest,
  B2BPrepaidCampaignUpdateRequest,
  CampaignsApiClient,
  CampaignUniqueCodeDetailsDto,
  DownloadCodeType,
  FreeDeliveryCampaignCreateRequest,
  LocalMarketingCampaignCreateRequest,
  LocalMarketingCampaignUpdateRequest,
  ProductCouponCampaignCreateRequest,
  RestaurantsClaimsCodesCreateRequest,
  ShopBasicInfo,
} from '@kotipizzagroup/kotipizza-campaigns-api-client';
import { getConfiguration } from '../shared/utils/configuration';
import Axios from 'axios';
import { getRequestHeaders, postToSlack } from './utils';
import { ApiCampaign, PostApiCampaign } from '../../../types/global';
import { CampaignTypes } from '../views/CampaignsPage/shared';

let client: CampaignsApiClient | null = null;

const getApiClient = (): CampaignsApiClient => {
  if (client) return client;

  const baseUrl = getConfiguration<string>('campaignsApi.root', '');

  const instance = Axios.create({
    timeout: 10000,
    transformResponse: [],
  });

  instance.interceptors.request.use(async (config) => {
    const headers = await getRequestHeaders();
    config.headers = headers;
    return config;
  });

  client = new CampaignsApiClient(baseUrl, instance);
  return client;
};

export const fetchCampaignTypes = async (): Promise<string[]> => {
  const client = getApiClient();
  return client.getCampaignCategories();
};

export const fetchAllCampaignsShops = async (): Promise<ShopBasicInfo[]> => {
  const client = getApiClient();
  return client.getAllShops();
};

export const fetchCampaigns = async (campaignType: string): Promise<ApiCampaign[]> => {
  const client = getApiClient();
  return client.getCampaignsByCategory(campaignType, false);
};

export const fetchCampaign = async (campaignType: CampaignTypes, campaignId: string): Promise<any> => {
  //Return type is any, because it would be hard to get it working with old Campaign pages. New pages are using V2-functions which are properly typed.

  const client = getApiClient();

  switch (campaignType) {
    case CampaignTypes.B2BPrepaidCodes:
      return client.getB2BPrepaidCampaignById(campaignId);
    case CampaignTypes.Combo:
      return client.getComboCampaignById(campaignId);
    case CampaignTypes.FreeDelivery:
      return client.getFreeDeliveryCampaignById(campaignId);
    case CampaignTypes.LoyaltyDiscount:
      return client.getLoyaltyDiscountCampaignById(campaignId);
    case CampaignTypes.LoyaltyPrize:
      return client.getLoyaltyPrizeCampaignById(campaignId);
    case CampaignTypes.ProductCoupon:
      return client.getProductCouponCampaignById(campaignId);
    case CampaignTypes.RestaurantClaimCodes:
      return client.getRestaurantClaimCodesCampaignById(campaignId);
  }

  throw new Error(`Unknown campaign type: ${campaignType}`);
};

export const fetchCampaignV2 = async (campaignType: CampaignTypes, campaignId: string) => {
  const client = getApiClient();

  switch (campaignType) {
    case CampaignTypes.LocalMarketingCampaign:
      return client.getLocalMarketingCampaignById(campaignId);
    case CampaignTypes.ShopSpecificCampaign:
      return client.getShopSpecificCampaignById(campaignId);
  }

  throw new Error(`Unknown campaign type: ${campaignType}`);
};

export const fetchAllCampaignDeliveryMethods = async (): Promise<string[]> => {
  const client = getApiClient();
  return client.getDeliveryMethods();
};

export const getSingleCampaignCode = async (code: string): Promise<CampaignUniqueCodeDetailsDto> => {
  const client = getApiClient();
  return client.getUniqueCodeDetailsByCode(code);
};

export const createShopSpecificCampaign = async (campaignData: LocalMarketingCampaignCreateRequest) => {
  try {
    const client = getApiClient();
    const response = await client.createShopSpecificCampaign(campaignData);

    await postToSlack(`Shop specific campaign ${response.name} created with id: ${response.campaignId}`);

    return response;
  } catch (error) {
    await postToSlack(`Error creating shop specific campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

export const createLocalMarketingCampaign = async (campaignData: LocalMarketingCampaignCreateRequest) => {
  try {
    const client = getApiClient();
    const response = await client.createLocalMarketingCampaign(campaignData);

    await postToSlack(`Local marketing campaign ${response.name} created with id: ${response.campaignId}`);

    return response;
  } catch (error) {
    await postToSlack(`Error creating local marketing campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

const createB2BPrepaidCodesCampaign = async (campaignType: string, campaignData: B2BPrepaidCampaignCreateRequest) => {
  try {
    const client = getApiClient();
    const response = await client.createB2BPrepaidCodesCampaign(campaignData);

    await postToSlack(`${campaignType}, ${response.name} created with id: ${response.campaignId}`);

    return response;
  } catch (error) {
    await postToSlack(`Error creating ${campaignType} campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

const createProductCouponCampaign = async (campaignType: string, campaignData: ProductCouponCampaignCreateRequest) => {
  try {
    const client = getApiClient();
    const response = await client.createProductCouponCampaign(campaignData);

    await postToSlack(`${campaignType}, ${response.name} created with id: ${response.campaignId}`);

    return response;
  } catch (error) {
    await postToSlack(`Error creating ${campaignType} campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

const createFreeDeliveryCampaign = async (campaignType: string, campaignData: FreeDeliveryCampaignCreateRequest) => {
  try {
    const client = getApiClient();
    const response = await client.createFreeDeliveryCampaign(campaignData);

    await postToSlack(`${campaignType}, ${response.name} created with id: ${response.campaignId}`);

    return response;
  } catch (error) {
    await postToSlack(`Error creating ${campaignType} campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

const createRestaurantClaimCodesCampaign = async (
  campaignType: string,
  campaignData: RestaurantsClaimsCodesCreateRequest
) => {
  try {
    const client = getApiClient();
    const response = await client.createRestaurantClaimCodesCampaign(campaignData);

    for (const campaign of response) {
      await postToSlack(`${campaignType}, ${campaign.name} created with id: ${campaign.campaignId}`);
    }

    return response;
  } catch (error) {
    await postToSlack(`Error creating ${campaignType} campaign: ${error}`);
    throw new Error('Error creating campaign');
  }
};

export const createCampaign = async (campaignType: string, campaignData: unknown) => {
  switch (campaignType) {
    case CampaignTypes.ProductCoupon:
      return createProductCouponCampaign(campaignType, campaignData as ProductCouponCampaignCreateRequest);
    case CampaignTypes.B2BPrepaidCodes:
      return createB2BPrepaidCodesCampaign(campaignType, campaignData as B2BPrepaidCampaignCreateRequest);
    case CampaignTypes.FreeDelivery:
      return createFreeDeliveryCampaign(campaignType, campaignData as FreeDeliveryCampaignCreateRequest);
    case CampaignTypes.RestaurantClaimCodes:
      return createRestaurantClaimCodesCampaign(campaignType, campaignData as RestaurantsClaimsCodesCreateRequest);
  }
};

export const updateCampaign = async (campaignType: string, campaignData: PostApiCampaign) => {
  const client = getApiClient();

  switch (campaignType) {
    case CampaignTypes.B2BPrepaidCodes:
      return client.updateB2BPrepaidCodeCampaign(campaignData as B2BPrepaidCampaignUpdateRequest);
    case CampaignTypes.FreeDelivery:
      return client.updateFreeDeliveryCampaign(campaignData);
    case CampaignTypes.LocalMarketingCampaign:
      return client.updateLocalMarketingCampaign(campaignData);
    case CampaignTypes.LoyaltyDiscount:
      return client.updateLocalMarketingCampaign(campaignData);
    case CampaignTypes.ProductCoupon:
      return client.updateProductCouponCampaign(campaignData);
    case CampaignTypes.ShopSpecificCampaign:
      return client.updateShopSpecificCampaign(campaignData);
  }
};

export const updateCampaignV2 = async (campaignType: string, campaignData: LocalMarketingCampaignUpdateRequest) => {
  const client = getApiClient();

  switch (campaignType) {
    case CampaignTypes.LocalMarketingCampaign:
      return client.updateLocalMarketingCampaign(campaignData);
    case CampaignTypes.ShopSpecificCampaign:
      return client.updateShopSpecificCampaign(campaignData);
  }
};

export const fetchClaimCodesCsv = async (campaignIds: string[]) => {
  const client = getApiClient();
  return client.downloadClaimCodesCsv({
    campaignIds,
  });
};

export const fetchCampaignCodesExcel = async (campaignId: string, downloadCodeType: DownloadCodeType) => {
  const client = getApiClient();
  return client.downloadUniqueCodesExcelFile(campaignId, downloadCodeType);
};
