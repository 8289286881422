import {
  Divider,
  Drawer,
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Toolbar,
} from '@mui/material';
import {
  Egg,
  Category,
  Inventory2Outlined as WarehouseIcon,
  MenuBookOutlined as ProductMenuIcon,
  List as ListIcon,
  LocalPizza,
  AssignmentTurnedIn,
  PersonOutline,
  RedeemOutlined,
  StoreMallDirectory,
  CalendarMonthOutlined,
  Clear,
  Place,
  ComputerOutlined,
  Info,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useUserPermissions } from '../../../apis/user-details';
import {
  CAMPAIGNS_READ,
  HasAllPermissions,
  LOYALTY_READ,
  ORDERS_READ,
  PRODUCTS_READ,
  SHOPS_READ,
} from '../../../shared/utils/permissions';
import { useTranslation } from 'react-i18next';
import { isNotProd } from '../../../shared/utils/configuration';

const drawerWidth = 240;

const PREFIX = 'SideDrawer';
const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
};

const StyledSideDrawer = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
  },
}));

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.active {
    color: red;
  }

  & > .MuiListItemButton-root {
    max-width: ${drawerWidth}px;
  }
`;

interface SideDrawerProps {
  isOpen: boolean;
  handleDrawerToggle: () => void;
}

const SideDrawer = ({ isOpen, handleDrawerToggle }: SideDrawerProps): JSX.Element => {
  const { roles } = useUserPermissions();
  const { t } = useTranslation();
  const isDevOrQa = isNotProd();

  const drawer = (
    <StyledSideDrawer className={classes.drawer}>
      <Toolbar />
      <List>
        {HasAllPermissions(roles, [LOYALTY_READ]) && (
          <ListItemButton component={NavLinkStyled} to="/customers">
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText secondary={t('nav.customers')} />
          </ListItemButton>
        )}
        {HasAllPermissions(roles, [CAMPAIGNS_READ]) && (
          <ListItemButton component={NavLinkStyled} to="/campaigns">
            <ListItemIcon>
              <RedeemOutlined />
            </ListItemIcon>
            <ListItemText secondary={t('nav.campaigns')} />
          </ListItemButton>
        )}
        <NavLinkStyled to="/holidays">
          <ListItemButton>
            <ListItemIcon>
              <CalendarMonthOutlined />
            </ListItemIcon>
            <ListItemText secondary={t('nav.holidays')} />
          </ListItemButton>
        </NavLinkStyled>
        {HasAllPermissions(roles, [ORDERS_READ]) && isDevOrQa && (
          <ListItemButton component={NavLinkStyled} to="/orders">
            <ListItemIcon>
              <ComputerOutlined />
            </ListItemIcon>
            <ListItemText secondary={t('nav.orders')} />
          </ListItemButton>
        )}
        {HasAllPermissions(roles, [PRODUCTS_READ]) && (
          <>
            <Divider sx={{ borderBottomWidth: 3 }} />
            <ListSubheader>{t('nav.products')}</ListSubheader>
            <ListItemButton component={NavLinkStyled} to="/products">
              <ListItemIcon>
                <LocalPizza />
              </ListItemIcon>
              <ListItemText secondary={t('nav.products')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/upcoming-products">
              <ListItemIcon>
                <AssignmentTurnedIn />
              </ListItemIcon>
              <ListItemText secondary={t('nav.upcomingProducts')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/ingredients">
              <ListItemIcon>
                <Egg />
              </ListItemIcon>
              <ListItemText secondary={t('nav.ingredients')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/ingredient-dosings">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText secondary={t('nav.ingredientDosings')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/product-categories">
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText secondary={t('nav.productCategories')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/warehouse-items">
              <ListItemIcon>
                <WarehouseIcon />
              </ListItemIcon>
              <ListItemText secondary={t('nav.warehouseItems')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/product-menus">
              <ListItemIcon>
                <ProductMenuIcon />
              </ListItemIcon>
              <ListItemText secondary={t('nav.productMenus')} />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/out-of-stock">
              <ListItemIcon>
                <Clear />
              </ListItemIcon>
              <ListItemText secondary={t('nav.outOfStockProducts')} />
            </ListItemButton>
          </>
        )}
        {HasAllPermissions(roles, [SHOPS_READ]) && (
          <>
            <Divider sx={{ borderBottomWidth: 3 }} />
            <ListSubheader>{t('restaurants.list.title')}</ListSubheader>
            <ListItemButton component={NavLinkStyled} to="/restaurants">
              <ListItemIcon>
                <StoreMallDirectory />
              </ListItemIcon>
              <ListItemText secondary="Ravintolat" />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/zones">
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText secondary="Kuljetusalueet" />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/zones-v2">
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText secondary="Kuljetusalueet v2" />
            </ListItemButton>
            <ListItemButton component={NavLinkStyled} to="/payment-methods-exceptions">
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText secondary={t('Exceptions')} />
            </ListItemButton>
          </>
        )}
      </List>
    </StyledSideDrawer>
  );

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default SideDrawer;
