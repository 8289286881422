import { CampaignBasicInfo, ClaimCodeType, InvoicingDetails, InvoicingInfo } from './types';
import dayjs from 'dayjs';
import { GlobalState } from 'little-state-machine';
import { CampaignsForm } from '../../../global';
import { fetchCampaignCodesExcel } from '../../apis/campaigns-api';
import {
  AllowedTimeOfDay,
  AllowedWeekdays,
  CampaignTypeForAccounting,
  DownloadCodeType,
} from '@kotipizzagroup/kotipizza-campaigns-api-client';
import { fetchClaimCodesCsv } from '../../apis/campaigns-api';

export const AutocompleteOff = {
  autoComplete: 'new-password',
  form: {
    autoComplete: 'off',
  },
};

export const defaultFormFreeDelivery = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: { ...getDefaultCampaignObject(), disablePreordersOnCampaign: false },
  };
};

export const defaultFormBasicInfo = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: { ...getDefaultCampaignObject() },
  };
};

export const updateCampaignsForm = (state: GlobalState, payload: CampaignsForm): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      ...state.campaignsForm,
      ...payload,
    },
  };
};

export const defaultFormStateClaimCodes = (state: GlobalState): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      start: dayjs().startOf('day').format(DateTimePickerFormat),
      end: dayjs().endOf('day').format(DateTimePickerFormat),
      claimCodesConfigs: Object.values(ClaimCodeType)
        .filter((x) => !isNaN(Number(x)))
        .map((type) => {
          return {
            claimCodeType: type,
            amountOfCodes: 0,
          };
        }),
    },
  };
};

export const defaultFormProductCoupon = (
  state: GlobalState,
  campaignAccountingType: CampaignTypeForAccounting
): GlobalState => {
  return {
    ...state,
    campaignsForm: {
      ...getDefaultCampaignObject(),
      campaignAccountingType: campaignAccountingType,
      requireLogIn: true,
    },
  };
};

const marketingInvoicingDefails: InvoicingInfo = {
  businessId: '00000000',
  companyName: 'Kotipizza Oyj',
  contactPerson: 'Markkinointi',
  invoiceReferenceNumber: 'Markkinointi',
};

export const getDefaultCampaignObject = (): CampaignBasicInfo & InvoicingDetails => {
  const startDate = dayjs().startOf('day');
  const endDate = dayjs().endOf('day');
  return {
    combinable: true,
    applyDiscountOnDelivery: false,
    name: '',
    requireLogIn: false,
    start: startDate.format(DateTimePickerFormat),
    end: endDate.format(DateTimePickerFormat),
    description: '',
    added: dayjs().toISOString(),
    allowedTimeOfDay: AllowedTimeOfDay.All,
    allowedWeekdays: AllowedWeekdays.All,
    campaignTypeForAnalytics: undefined,
    invoicingDetails: marketingInvoicingDefails,
  };
};

export const getDefaultShopSpecificCampaignObject = () => {
  const startDate = dayjs().startOf('day');
  const endDate = dayjs().endOf('day');
  return {
    combinable: false,
    applyDiscountOnDelivery: false,
    name: '',
    deliveryMethods: [],
    shopExternalIds: [],
    restrictedProductSizes: [],
    requireLogIn: false,
    start: startDate.format(DateTimePickerFormat),
    end: endDate.format(DateTimePickerFormat),
    description: '',
    added: dayjs().toISOString(),
    allowedTimeOfDay: AllowedTimeOfDay.All,
    allowedWeekdays: AllowedWeekdays.All,
    campaignTypeForAnalytics: undefined,
    invoicingDetails: marketingInvoicingDefails,
    isUnlimitedPublicCodes: null,
  };
};

export const getDefaultLocalMarketingCampaignObject = () => {
  const startDate = dayjs().startOf('day');
  const endDate = dayjs().endOf('day');
  return {
    combinable: false,
    applyDiscountOnDelivery: false,
    name: '',
    deliveryMethods: [],
    shopExternalIds: [],
    restrictedProductSizes: [],
    requireLogIn: false,
    start: startDate.format(DateTimePickerFormat),
    end: endDate.format(DateTimePickerFormat),
    description: '',
    added: dayjs().toISOString(),
    allowedTimeOfDay: AllowedTimeOfDay.All,
    allowedWeekdays: AllowedWeekdays.All,
    campaignTypeForAnalytics: undefined,
    isUnlimitedPublicCodes: null,
  };
};

export const downloadCodeExcel = async (
  campaignId: string,
  campaignName: string,
  downloadCodeType: DownloadCodeType = DownloadCodeType.All
): Promise<void> => {
  try {
    const { data } = await fetchCampaignCodesExcel(campaignId, downloadCodeType);
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${campaignName}.xlsx`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadClaimCodesCsv = async (campaignIds: string[]): Promise<void> => {
  const data = await fetchClaimCodesCsv(campaignIds);
  const url = window.URL.createObjectURL(data.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'claim-codes.csv');

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
  return Promise.resolve();
};

export const DateTimePickerFormat = 'YYYY-MM-DDTHH:mm';
export const DatePickerFormat = 'YYYY-MM-DD';

export enum CampaignTypes {
  B2BPrepaidCodes = 'b2bprepaidcodes',
  RestaurantClaimCodes = 'restaurantsclaimcodes',
  LocalMarketingCampaign = 'localmarketingcampaign',
  ShopSpecificCampaign = 'shopspecificcampaign',
  LoyaltyPrize = 'loyaltyprize',
  LoyaltyDiscount = 'loyaltydiscount',
  Combo = 'combo',
  FreeDelivery = 'freedelivery',
  ProductCoupon = 'productcoupon',
}

export const PerfettaProduct = 1102;
