import { ProductDto, ProductSizeDto } from '@kotipizzagroup/kotipizza-products-api-client';
import { DeliveryFeeDiscountOption } from '../../../../types/global';
import {
  AllowedTimeOfDay,
  AllowedWeekdays,
  CampaignTypeForAccounting,
  LocalMarketingCampaignDiscountType,
} from '@kotipizzagroup/kotipizza-campaigns-api-client';

export type InvoicingDetails = {
  invoicingDetails: InvoicingInfo;
};

export type IsUnlimitedPublicCodes = {
  isUnlimitedPublicCodes: boolean | null;
};

export type LocalMarketingInfo = {
  discountType: LocalMarketingCampaignDiscountType;
  discountValue: number;
  campaignAccountingType: CampaignTypeForAccounting;
  campaignCodeType: CampaignCodeType;
  requireLogIn: boolean;
  shopExternalIds: string[];
  deliveryMethods: string[];
  restrictedProductSizes: number[];
  personalCodeInfo?: PersonalCodeInfo;
  publicCode?: string;
  uniqueCodeCount?: number;
  uniqueCodePrefix?: string;
  publicUsageLimit?: number;
  minimumOrderValue?: number;
  allowedTimeOfDay?: AllowedTimeOfDay;
  allowedWeekdays?: AllowedWeekdays;
  restrictedProducts?: number[];
  excludeModified?: boolean;
  deliveryFeeDiscountOption?: DeliveryFeeDiscountOption;
};

export type CampaignProductCouponInfo = {
  name: string;
  description: string;
  promotionCode: string;
  requireLogIn: boolean;
  disablePreordersOnCampaign: boolean;
  isOffInLunchTime: boolean;
  start: string;
  end: string;
  added: string;
};

export type CouponProductDiscountConditions = {
  restrictedSize: ProductSizeDto;
  restrictedProduct?: ProductDto;
  restrictedModification?: boolean;
  discountAmount: number;
  minimumOrderValue: number;
  maximumIngredientsNumber?: number;
  campaignMaxDiscount?: number;
};

export type CampaignFreeDeliveryInfo = {
  name: string;
  requireLogIn: boolean;
  disablePreordersOnCampaign: boolean;
  isOffInLunchTime: boolean;
  minimumOrderValue: number;
  start: string;
  end: string;
  description: string;
  added: string;
};

export type CampaignBasicInfo = {
  name: string;
  combinable: boolean;
  requireLogIn: boolean;
  applyDiscountOnDelivery: boolean;
  start: string;
  end: string;
  description: string;
  added: string;
  allowedTimeOfDay: number;
  allowedWeekdays: number;
  campaignTypeForAnalytics?: CampaignTypeForAnalytics;
};

export type PersonalCodeInfo = {
  uniqueCodeCount: number;
  uniqueCodePrefix: string;
  discountAmount: number;
};

export type DisablePreordersOnCampaign = {
  disablePreordersOnCampaign: boolean;
};

export type InvoicingInfo = {
  businessId: string;
  companyName: string;
  contactPerson: string;
  invoiceReferenceNumber: string;
  billingDate?: string;
  billingSurchargeAmount?: string;
  billingType?: string;
  billingRequirePrepaid?: boolean;
};

export type RestaurantClaimCodesInfo = {
  start: string;
  end: string;
  claimCodesConfigs: ClaimCodesConfig[];
  shopExternalIds: string[];
  codesValidInAllShops: string; // Using string as bools get surprisingly converted to strings: https://github.com/react-hook-form/react-hook-form/issues/2241 and https://github.com/mui/material-ui/issues/13250
  allowUnregisteredCustomer: boolean;
  groupPrefix?: string;
  campaignNameSuffix?: string;
};

export type ClaimCodesConfig = {
  amountOfCodes: number;
  claimCodeType: ClaimCodeType;
};

export type ProductRestriction = {
  restrictedSizes?: number[];
  restricedProducts?: number[];
};

export type WarehouseItemCategoryInfo = {
  warehouseItemCategoryId?: number | null;
};

export type B2bCampaign = CampaignBasicInfo & PersonalCodeInfo & ProductRestriction;

export enum ClaimCodeType {
  FreeDelivery,
  FreeMediumPizza,
  FreeLargePizza,
  FreeSmallDrink,
  FreeLargeDrink,
  FreeDip,
  FreeIceCream,
  FreeCandy,
  Minus50PercentMPizza,
  FixedTwoEuroDiscount,
  FixedFiveEuroDiscount,
  FreeLankkuPizza,
  FreeMiniMonster,
}

export enum CampaignTypeForAnalytics {
  Undefined = 0,
  LunchDrink = 1,
  FreeDelivery = 2,
  ProductCoupon = 3,
  ProductDiscount = 4,
  B2BPrepaid = 5,
  ClaimCode = 6,
  LocalMarketingCampaign = 7,
  LoyaltyPrizes = 8,
  CrossSell = 9,
  LoyaltyTargetedMarketing = 10,
  BuySomeGetSome = 11,
}

export enum CampaignCodeType {
  Public = 3,
  Personal = 2,
  NoCode = 1,
}

export enum CampaignBillingType {
  OrderedCodes,
  UsedCodes,
}

export enum DeliveryFeeDiscountType {
  None = 0,
  FixedPrice = 1,
}
