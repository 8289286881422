import {
  AdyenMerchantListItemDto,
  AdyenOnboardingControllerMerchantType,
  AdyenOnboardingRequest,
  AdyenStoreData,
  AdyenUserData,
  EdenredConnectMerchantRequest,
  EdenredMerchantListItemDto,
  KlarnaCreateMerchantRequest,
  KlarnaMerchantListItemDto,
  KlarnaOnboardingResendEmailRequest,
  MerchantDto,
  MultiplePaymentMethodsRequest,
  PaymentMethodSettings,
  PaymentMethodSettingsControllerMerchantType,
  PaymentsAPIClient,
  PayoutSettings,
} from '@kotipizzagroup/kotipizza-payments-api-admin-client';
import { getConfiguration } from '../shared/utils/configuration';
import Axios from 'axios';
import { getRequestHeaders } from './utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

let client: PaymentsAPIClient | null = null;

export type AdyenState = {
  [key: string]: {
    payoutSettings: PayoutSettings[];
    paymentMethodSettings: PaymentMethodSettings[];
  };
};

export enum klarnaMerchantStatus {
  PENDING = 0,
  TRANSACTIONS_DISABLED = 1,
  TRANSACTIONS_ENABLED = 2,
  PAYOUTS_ENABLED = 3,
  ADDITIONAL_REVIEW_1 = 4,
  ADDITIONAL_REVIEW_2 = 5,
  SUSPENDED = 6,
  PRE_ASSESSMENT_PENDING = 7,
  DORMANT = 8,
}

export type KlarnaState = {
  currentStatus?: number;
  vatId?: string;
};

type ShopPaymentProviderStatus = {
  shopExternalId: string;
  adyenState: AdyenState;
  klarnaState: KlarnaState;
  updatedAt: string;
};

const getApiClient = (): PaymentsAPIClient => {
  if (client) return client;

  const baseUrl = getConfiguration<string>('paymentsApi.root', '');

  const instance = Axios.create({
    timeout: 10000,
    transformResponse: [],
  });

  instance.interceptors.request.use(async (config) => {
    const headers = await getRequestHeaders();
    config.headers = headers;
    return config;
  });

  client = new PaymentsAPIClient(baseUrl, instance);
  return client;
};

export const paymentsApiQueryKeys = {
  allMerchants: 'all-merchants',
  allAdyenMerchants: 'all-adyen-merchants',
  allAdyenUsers: 'all-adyen-users',
  allEdenredMerchants: 'all-edenred-merchants',
  allKlarnaMerchants: 'all-klarna-merchants',
  allShopPaymentStatus: 'all-shop-payment-status',
  methodSettingsEcom: 'method-settings-ecom',
  methodSettingsPos: 'method-settings-pos',
  payoutSettings: 'payout-settings',
};

const getAllMerchants = (): Promise<MerchantDto[]> => {
  const client = getApiClient();
  return client.getAllMerchants();
};

export const useAllMerchants = (): UseQueryResult<MerchantDto[], unknown> => {
  return useQuery<MerchantDto[]>([paymentsApiQueryKeys.allMerchants], getAllMerchants, { retry: false });
};

export const getAllAdyenMerchants = async (): Promise<AdyenMerchantListItemDto[]> => {
  let currentPage = 1;
  const client = getApiClient();
  const initialResponse = await client.getAllAdyenMerchants(currentPage);
  const merchants = initialResponse.adyenMerchants;
  const promises = [];

  while (initialResponse.pagesTotal >= currentPage) {
    currentPage++;
    promises.push(client.getAllAdyenMerchants(currentPage));
  }

  const responses = await Promise.all(promises);
  responses.forEach((r) => merchants.push(...r.adyenMerchants));
  return merchants;
};

export const useAllAdyenMerchants = (): UseQueryResult<AdyenMerchantListItemDto[], unknown> => {
  return useQuery<AdyenMerchantListItemDto[]>([paymentsApiQueryKeys.allAdyenMerchants], () => getAllAdyenMerchants(), {
    retry: false,
  });
};

export const getAllEdenredMerchants = async (): Promise<EdenredMerchantListItemDto[]> => {
  const client = getApiClient();
  const response = await client.getAllEdenredMerchants();
  const merchants = response.edenredMerchants;
  return merchants;
};

export const useAllEdenredMerchants = (): UseQueryResult<EdenredMerchantListItemDto[], unknown> => {
  return useQuery<EdenredMerchantListItemDto[]>([paymentsApiQueryKeys.allEdenredMerchants], getAllEdenredMerchants, {
    retry: false,
  });
};

export const getAllKlarnaMerchants = async (): Promise<KlarnaMerchantListItemDto[]> => {
  const client = getApiClient();
  const response = await client.getAllKlarnaMerchants();
  const merchants = response.klarnaMerchants;
  return merchants;
};

export const useAllKlarnaMerchants = (): UseQueryResult<KlarnaMerchantListItemDto[], unknown> => {
  return useQuery<KlarnaMerchantListItemDto[]>([paymentsApiQueryKeys.allKlarnaMerchants], getAllKlarnaMerchants, {
    retry: false,
  });
};

export const getAllAdyenUsers = async (): Promise<AdyenUserData[]> => {
  let currentPage = 1;
  const client = getApiClient();
  const initialResponse = await client.getAdyenUsers(currentPage);
  const users = initialResponse.adyenUsers;
  const promises = [];
  while (initialResponse.pagesTotal >= currentPage) {
    currentPage++;
    promises.push(client.getAdyenUsers(currentPage));
  }

  const responses = await Promise.all(promises);
  responses.forEach((r) => users.push(...r.adyenUsers));
  return users;
};

export const useAllAdyenUsers = (): UseQueryResult<AdyenUserData[], unknown> => {
  return useQuery<AdyenUserData[]>([paymentsApiQueryKeys.allAdyenUsers], getAllAdyenUsers, {
    retry: false,
  });
};

const parseKlarnaState = (json: string): KlarnaState => {
  const klarnaState = JSON.parse(json);

  return {
    currentStatus: klarnaState.status?.current,
    vatId: klarnaState.business_information?.vat_id,
  };
};

export const getAllShopPaymentStatus = async (): Promise<ShopPaymentProviderStatus[]> => {
  const client = getApiClient();
  const response = await client.getShopPaymentStatus();

  return response.map((entry) => ({
    shopExternalId: entry.shopExternalId,
    adyenState: JSON.parse(entry.adyenState) as AdyenState,
    klarnaState: parseKlarnaState(entry.klarnaState),
    updatedAt: entry.updatedAt,
  }));
};

export const useAllShopPaymentStatus = (): UseQueryResult<ShopPaymentProviderStatus[], unknown> => {
  return useQuery<ShopPaymentProviderStatus[]>([paymentsApiQueryKeys.allShopPaymentStatus], getAllShopPaymentStatus, {
    retry: false,
  });
};

export const refetchRestaurantPaymentStatus = async (shopExternalId: string) => {
  const client = getApiClient();
  return await client.refetchSingleShopPaymentStatus(shopExternalId);
};

export const connectShopToAdyenMerchant = (
  shopExternalId: string,
  merchantType: AdyenOnboardingControllerMerchantType,
  adyenMerchantId: string
): Promise<void> => {
  const client = getApiClient();
  return client.connectShopToMerchant(shopExternalId, merchantType, { adyenMerchantId });
};

export const connectShopToKlarnaMerchant = (shopExternalId: string, klarnaMerchantId: string): Promise<void> => {
  const client = getApiClient();
  return client.connectKlarnaMerchant({ shopExternalId, merchantId: klarnaMerchantId });
};

export const syncMerchant = (shopExternalId: string) => {
  const client = getApiClient();
  return client.syncMerchant(shopExternalId);
};

export const createAdyenPosStore = (shopExternalId: string, payload: AdyenStoreData) => {
  const client = getApiClient();
  return client.createAdyenPosStore(shopExternalId, payload);
};

export const onboardToAdyen = (shopExternalId: string, payload: AdyenOnboardingRequest) => {
  const client = getApiClient();
  return client.onboardToAdyen(shopExternalId, payload);
};

export const onboardToEdenred = (shopExternalId: string, payload: EdenredConnectMerchantRequest) => {
  const client = getApiClient();
  return client.connectShopToEdenredMerchant(shopExternalId, payload);
};

export const onboardToKlarna = (payload: KlarnaCreateMerchantRequest) => {
  const client = getApiClient();
  return client.createKlarnaMerchant(payload);
};

export const resendKlarnaOnboardingEmail = (payload: KlarnaOnboardingResendEmailRequest) => {
  const client = getApiClient();
  return client.resendKlarnaOnboardingEmail(payload);
};

const getEcomPaymentMethodSettings = (shopExternalId: string): Promise<PaymentMethodSettings[]> => {
  const client = getApiClient();
  return client.getPaymentMethodSettings(shopExternalId, PaymentMethodSettingsControllerMerchantType.ECOM);
};

const getPosPaymentMethodSettings = (shopExternalId: string): Promise<PaymentMethodSettings[]> => {
  const client = getApiClient();
  return client.getPaymentMethodSettings(shopExternalId, PaymentMethodSettingsControllerMerchantType.POS);
};

export const useEcomPaymentMethodSettings = (
  shopExternalId: string,
  enabled = true
): UseQueryResult<PaymentMethodSettings[], unknown> => {
  return useQuery<PaymentMethodSettings[]>(
    [paymentsApiQueryKeys.methodSettingsEcom, shopExternalId],
    () => getEcomPaymentMethodSettings(shopExternalId),
    { retry: false, enabled }
  );
};

export const usePosPaymentMethodSettings = (
  shopExternalId: string,
  enabled = true
): UseQueryResult<PaymentMethodSettings[], unknown> => {
  return useQuery<PaymentMethodSettings[]>(
    [paymentsApiQueryKeys.methodSettingsPos, shopExternalId],
    () => getPosPaymentMethodSettings(shopExternalId),
    { retry: false, enabled }
  );
};

export const requestEcomPaymentMethods = (shopExternalId: string, payload: MultiplePaymentMethodsRequest) => {
  const client = getApiClient();
  return client.requestMultiplePaymentMethods(
    shopExternalId,
    PaymentMethodSettingsControllerMerchantType.ECOM,
    payload
  );
};

export const requestPosPaymentMethods = (shopExternalId: string, payload: MultiplePaymentMethodsRequest) => {
  const client = getApiClient();
  return client.requestMultiplePaymentMethods(shopExternalId, PaymentMethodSettingsControllerMerchantType.POS, payload);
};

export const deactivateFromAdyen = (shopExternalId: string) => {
  const client = getApiClient();
  return client.deactivateFromAdyen(shopExternalId);
};

export const deactivateFromEdenred = (shopExternalId: string) => {
  const client = getApiClient();
  return client.deactivateFromEdenred(shopExternalId);
};

export const deactivateFromKlarna = (shopExternalId: string) => {
  const client = getApiClient();
  return client.disconnectKlarnaMerchant({ shopExternalId });
};

export const getAdyenMerchantPayoutSettings = async (merchantId?: string): Promise<PayoutSettings[]> => {
  const client = getApiClient();
  return merchantId ? client.payoutSettings(merchantId) : [];
};

export const useAdyenMerchantPayoutSettings = (
  merchantId?: string,
  enabled = true
): UseQueryResult<PayoutSettings[], unknown> => {
  return useQuery<PayoutSettings[]>(
    [paymentsApiQueryKeys.payoutSettings, merchantId],
    () => getAdyenMerchantPayoutSettings(merchantId),
    { retry: false, enabled }
  );
};

export const activateAdyenMerchant = async (merchantId: string): Promise<void> => {
  const client = getApiClient();
  return client.activateMerchant(merchantId);
};

export const resetKlarnaApiKey = async (klarnaMerchantId: string): Promise<void> => {
  const client = getApiClient();
  return client.resetMerchantApiKey({
    klarnaMerchantId,
  });
};
