import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Restaurant, ShopOpeningHour } from '../../../../../types/Restaurant';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { restaurantPageState } from '../state';
import { useCallback, useMemo, useState } from 'react';
import dayjs from '../../../shared/utils/dayJsCustomParseFormat';
import { Commerce } from '@kotipizzagroup/kotipizza-shared-types';
import {
  useAllIngredients,
  useAllOutOfStockIngredients,
  useAllOutOfStockProducts,
  useAllProducts,
} from '../../../apis/products-api';

type Props = {
  restaurants: Restaurant[];
};

type OpeningHoursCheckResponse = {
  exception?: openingHourException;
  notices: string[];
};

type OutOfStockItem = {
  name: string;
  added: number;
  id: number;
};

type Row = {
  id: string;
  displayName: string;
  isActive: boolean;
  currentPickupEstimate: number | openingHourException;
  currentDeliveryEstimate: number | openingHourException;
  notices: string[];
  outOfStockItems: OutOfStockItem[];
};

enum openingHourException {
  Restricted = 'Restricted',
  Closed = 'Closed',
}

const expectedClosingTime = '20:30';
const lastClosingTime = '06:00';
const deliveryOpeningHourId = 2;
const pickupOpeningHourId = 4;

const Info: React.FC<Props> = ({ restaurants }) => {
  const { t } = useTranslation();

  const { data: outOfStockProducts, isLoading: isOutOfStockProductsLoading } = useAllOutOfStockProducts();
  const { data: outOfStockIngredients, isLoading: isOutOfStockIngredientsLoading } = useAllOutOfStockIngredients();

  const { data: products, isLoading: isProductsLoading } = useAllProducts();
  const { data: ingredients, isLoading: isIngredientsLoading } = useAllIngredients();

  const checkOpening = useCallback(
    (
      shopOpeningHours: ShopOpeningHour[],
      openingHourId: number,
      methodName: string,
      isMethodOpen: boolean
    ): OpeningHoursCheckResponse => {
      const shopOpeningHour = shopOpeningHours.find(
        (shopOpeningHour) => shopOpeningHour.openingHourtypeID === openingHourId
      );

      if (!isMethodOpen) {
        return {
          exception: openingHourException.Closed,
          notices: [`${methodName} ${t('disabled')}`],
        };
      }

      if (!shopOpeningHour) {
        return {
          exception: openingHourException.Closed,
          notices: [`${t('Failed to retrieve opening hours')} (${methodName})`],
        };
      }

      const shopClosingTime = getEndTime(shopOpeningHour);

      const isResticted =
        isTimeBefore(shopClosingTime, expectedClosingTime) && !isTimeBefore(shopClosingTime, lastClosingTime);

      if (isResticted) {
        return {
          notices: [`${methodName} ${getOpeningHours(shopOpeningHour)}`],
        };
      }

      return {
        notices: [],
      };
    },
    [t]
  );

  const getRestaurantOutOfStockProducts = useCallback(
    (restaurantId: string): OutOfStockItem[] => {
      if (!outOfStockProducts || !outOfStockIngredients) return [];

      const restaurantOutOfStockProducts = outOfStockProducts.filter(
        (product) => product.externalShopId === restaurantId
      );
      const restaurantOutOfStockIngredients = outOfStockIngredients.filter(
        (ingredient) => ingredient.externalShopId === restaurantId
      );

      const allRestaurantOutOfStockItems: OutOfStockItem[] = [];
      const currentTime = dayjs();

      for (const outOfStockProduct of restaurantOutOfStockProducts) {
        const product = products?.find((p) => p.productId === outOfStockProduct.productId);
        if (product && outOfStockProduct.added) {
          allRestaurantOutOfStockItems.push({
            id: product.productId ?? -1,
            name: product.name,
            added: currentTime.diff(dayjs(outOfStockProduct.added), 'day'),
          });
        }
      }

      for (const outOfStockIngredient of restaurantOutOfStockIngredients) {
        const ingredient = ingredients?.find((i) => i.ingredientId === outOfStockIngredient.ingredientId);
        if (ingredient && outOfStockIngredient.added) {
          allRestaurantOutOfStockItems.push({
            id: ingredient.ingredientId ?? -1,
            name: ingredient.name,
            added: currentTime.diff(dayjs(outOfStockIngredient.added), 'day'),
          });
        }
      }

      return allRestaurantOutOfStockItems;
    },
    [ingredients, outOfStockIngredients, outOfStockProducts, products]
  );

  const isLoading =
    isOutOfStockProductsLoading || isOutOfStockIngredientsLoading || isProductsLoading || isIngredientsLoading;

  const state = restaurantPageState.getState();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: state.tabs.info.page,
  });

  const columns: GridColDef<Row>[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'isActive',
      headerName: t('Active'),
      width: 100,
      renderCell(params) {
        return params.row.isActive ? <CheckCircle color="success" /> : <Cancel color="disabled" />;
      },
    },
    {
      field: 'displayName',
      headerName: t('restaurants.list.name'),
      flex: 1,
      minWidth: 300,
      renderCell(params) {
        return <StyledLink to={`/restaurants/${params.row.id}`}>{params.row.displayName}</StyledLink>;
      },
    },
    {
      field: 'currentPickupEstimate',
      headerName: t('Pickup'),
      width: 100,
      renderCell(params) {
        return <Estimate estimateOrException={params.row.currentPickupEstimate} />;
      },
    },
    {
      field: 'currentDeliveryEstimate',
      headerName: t('Delivery'),
      width: 100,
      renderCell(params) {
        return <Estimate estimateOrException={params.row.currentDeliveryEstimate} />;
      },
    },
    {
      field: 'notices',
      headerName: t('Notices'),
      minWidth: 350,
      flex: 1,
      renderCell(params) {
        return (
          <div>
            {params.row.notices.map((notice) => (
              <WarningText key={`${params.row.id}-notice-${notice}`}>{notice}</WarningText>
            ))}
            {params.row.outOfStockItems.map((item) => (
              <OutOfStock key={`${params.row.id}-outofstock-${item.name}-${item.id}`} outOfStockItem={item} />
            ))}
          </div>
        );
      },
    },
  ];

  const rows: Row[] = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return restaurants.map((restaurant) => {
      const notices: string[] = [];
      const restaurantOutOfStockProducts = getRestaurantOutOfStockProducts(restaurant.shopExternalId);

      let pickupException: undefined | openingHourException;
      let deliveryException: undefined | openingHourException;

      if (!restaurant.hasWebsale) {
        pickupException = openingHourException.Closed;
        deliveryException = openingHourException.Closed;
        notices.push(t('websale disabled'));
      } else {
        const pickupOpeningHours = checkOpening(
          restaurant.shopOpeningHours ?? [],
          pickupOpeningHourId,
          'nouto',
          restaurant.hasPickup
        );

        const deliveryOpeningHours = checkOpening(
          restaurant.shopOpeningHours ?? [],
          deliveryOpeningHourId,
          'kuljetus',
          restaurant.hasDelivery
        );

        pickupException = pickupOpeningHours.exception;
        deliveryException = deliveryOpeningHours.exception;

        notices.push(...pickupOpeningHours.notices);
        notices.push(...deliveryOpeningHours.notices);
      }

      return {
        id: restaurant.shopExternalId,
        displayName: restaurant.displayName,
        isActive: restaurant.isActive,
        currentPickupEstimate: pickupException ?? restaurant.currentPickupEstimate,
        currentDeliveryEstimate: deliveryException ?? restaurant.currentDeliveryEstimate,
        notices: notices,
        outOfStockItems: restaurantOutOfStockProducts,
      };
    });
  }, [isLoading, restaurants, getRestaurantOutOfStockProducts, t, checkOpening]);

  const handlePagination = (model: GridPaginationModel) => {
    setPaginationModel(model);

    restaurantPageState.setState({
      tabs: {
        ...state.tabs,
        info: {
          ...state.tabs.info,
          page: model.page,
        },
      },
    });
  };

  return (
    <Box>
      <DataGridContainer>
        <DataGrid
          loading={isLoading}
          columns={columns}
          getRowHeight={(params) => {
            const defaultRowHeight = 52;

            const model = params.model as Row;
            const totalNotices = model.notices.length + model.outOfStockItems.length;

            if (totalNotices > 2) {
              return 'auto';
            }

            return defaultRowHeight;
          }}
          rows={rows}
          initialState={{ sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePagination}
        />
      </DataGridContainer>
    </Box>
  );
};

export const DataGridContainer = styled(Box)`
  height: calc(100vh - 400px);
`;

const getEndTime = (openingHours: Commerce.RestaurantOpeningHours): string => {
  const date = new Date().getDay();

  switch (date) {
    case 0:
      return openingHours.sunEndTime;
    case 1:
      return openingHours.monEndTime;
    case 2:
      return openingHours.tueEndTime;
    case 3:
      return openingHours.wedEndTime;
    case 4:
      return openingHours.thuEndTime;
    case 5:
      return openingHours.friEndTime;
    case 6:
      return openingHours.satEndTime;
    default:
      return '';
  }
};

const getOpeningHours = (openingHours: Commerce.RestaurantOpeningHours): string => {
  const date = new Date().getDay();

  switch (date) {
    case 0:
      return `${openingHours.sunStartTime} – ${openingHours.sunEndTime}`;
    case 1:
      return `${openingHours.monStartTime} – ${openingHours.monEndTime}`;
    case 2:
      return `${openingHours.tueStartTime} – ${openingHours.tueEndTime}`;
    case 3:
      return `${openingHours.wedStartTime} – ${openingHours.wedEndTime}`;
    case 4:
      return `${openingHours.thuStartTime} – ${openingHours.thuEndTime}`;
    case 5:
      return `${openingHours.friStartTime} – ${openingHours.friEndTime}`;
    case 6:
      return `${openingHours.satStartTime} – ${openingHours.satEndTime}`;
    default:
      return '';
  }
};

const isTimeBefore = (time: string, expected: string): boolean => {
  const timeFormat = 'HH:mm';
  return dayjs(time, timeFormat).isBefore(dayjs(expected, timeFormat));
};

const Estimate: React.FC<{ estimateOrException: openingHourException | number }> = ({ estimateOrException }) => {
  const { t } = useTranslation();

  if (estimateOrException === openingHourException.Closed) {
    return <ErrorText>{t('Closed')}</ErrorText>;
  }

  if (estimateOrException >= 60) {
    return <WarningText>{estimateOrException} min</WarningText>;
  }

  return <span>{estimateOrException} min</span>;
};

const OutOfStock: React.FC<{ outOfStockItem: OutOfStockItem }> = ({ outOfStockItem }) => {
  const { t } = useTranslation();
  return (
    <div>
      <ErrorSpan>{outOfStockItem.name}</ErrorSpan> {t('out of stock')}{' '}
      {outOfStockItem.added > 0 ? `${outOfStockItem.added} ${t('days ago')}` : t('today')}
    </div>
  );
};

const StyledLink = styled(Link)`
  color: green;
  text-decoration: none;
`;

const WarningText = styled.div`
  color: #c77700;
`;

const ErrorText = styled.div`
  color: #e31b0c;
  font-weight: bold;
`;

const ErrorSpan = styled.span`
  color: #e31b0c;
  font-weight: bold;
`;

export default Info;
