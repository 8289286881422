import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AdyenState,
  klarnaMerchantStatus,
  KlarnaState,
  paymentsApiQueryKeys,
  refetchRestaurantPaymentStatus,
  useAllMerchants,
  useAllShopPaymentStatus,
} from '../../../apis/payments-api';
import { Restaurant } from '../../../../../types/Restaurant';
import { MerchantDto } from '@kotipizzagroup/kotipizza-payments-api-admin-client';
import { useAllRestaurants } from '../../../apis/restaurants-service';

export type ErrorGroup = {
  [key: string]: string[];
};

export type ErrorGroupWithShopExternalId = {
  errors: ErrorGroup;
  shopExternalId: string;
};

type ExtendedRestaurant = Restaurant & {
  injectOrdersToS4D: boolean;
};

const requiredAdyenPaymentMethods = ['googlepay', 'mobilepay', 'visa', 'mc', 'applepay', 'ebanking_FI', 'amex'];

const validateKlarna = (klarnaState: KlarnaState, restaurant: Restaurant, merchant: MerchantDto): string[] => {
  const errors: string[] = [];

  if (!restaurant.hasWebsale) return errors;

  if (!merchant?.klarnaMerchantData) {
    errors.push('Klarna merchant missing');
  } else if (!klarnaState.currentStatus) {
    errors.push(`Klarna merchant status unknown (${merchant.klarnaMerchantData?.merchantId})`);
  } else {
    if (klarnaState.currentStatus !== klarnaMerchantStatus.PAYOUTS_ENABLED) {
      errors.push(
        `Status is ${klarnaMerchantStatus[klarnaState.currentStatus]} (${merchant.klarnaMerchantData?.merchantId})`
      );
    }
  }
  return errors;
};

const validateAdyen = (
  adyenState: AdyenState,
  restaurant: Restaurant,
  merchant: MerchantDto,
  isS4DInjectionEnabled: boolean
): string[] => {
  const errors: string[] = [];

  const ecomMerchantKey = Object.keys(adyenState).find((key) => key.includes('ECOM'));

  if (!ecomMerchantKey) {
    errors.push('ECOM merchant missing');
  } else {
    const ecomMerchant = adyenState[ecomMerchantKey];

    const payoutSettings = ecomMerchant.payoutSettings;
    const payoutMissing = payoutSettings.length === 0 || !payoutSettings[0].enabled;

    if (payoutMissing && restaurant.hasWebsale) {
      errors.push('ECOM merchant payout settings missing');
    }

    const paymentMethodSettings = ecomMerchant.paymentMethodSettings;

    for (const requiredMethod of requiredAdyenPaymentMethods) {
      const method = paymentMethodSettings.find((method) => method.type === requiredMethod);

      if (!method || (!method.enabled && restaurant.hasWebsale)) {
        errors.push(`${requiredMethod} is missing or disabled`);
      }
    }
  }

  if (!isS4DInjectionEnabled) {
    // Do not check POS merchant if restaurant does not have S4D enabled. This will be removed when PFT-8583 is done
    return errors;
  }

  const posMerchantKey = Object.keys(adyenState).find((key) => key.includes('KASSA'));

  if (!posMerchantKey) {
    errors.push('POS merchant missing');
  } else {
    const posMerchant = adyenState[posMerchantKey];
    const payoutSettings = posMerchant.payoutSettings;
    const payoutMissing = payoutSettings.length === 0 || !payoutSettings[0].enabled;

    if (payoutMissing) {
      errors.push('POS merchant payout settings missing');
    }
  }

  return errors;
};

export const usePaymentExceptions = (ignoreKlarnaMissing = false) => {
  const queryClient = useQueryClient();

  const { data: restaurants, isLoading: isLoadingRestaurants } = useAllRestaurants();

  const { data: shopPaymentProviderStatus = [], isLoading: isLoadingShopPaymentProviderStatus } =
    useAllShopPaymentStatus();

  const { data: merchants } = useAllMerchants();

  const isLoading = isLoadingRestaurants || isLoadingShopPaymentProviderStatus;

  const paymentExceptions = useMemo(() => {
    if (isLoading || !restaurants || !shopPaymentProviderStatus) return null;

    const entries = shopPaymentProviderStatus
      .filter((shopPaymentStatus) => {
        const restaurant = restaurants
          .filter((r) => r.isActive)
          .find((restaurant) => restaurant.shopExternalId === shopPaymentStatus.shopExternalId);
        return !!restaurant;
      })
      .map((shopPaymentStatus) => {
        const restaurant = restaurants
          .filter((r) => r.isActive)
          .find((restaurant) => restaurant.shopExternalId === shopPaymentStatus.shopExternalId);

        if (!restaurant) {
          const restaurantNotFoundError = `Unknown restaurant id ${shopPaymentStatus.shopExternalId}`;

          return {
            restaurantName: restaurantNotFoundError,
            updatedAt: shopPaymentStatus.updatedAt,
            shopExternalId: shopPaymentStatus.shopExternalId,
            errors: {
              Restaurant: [restaurantNotFoundError],
            },
          };
        }

        const merchant = merchants?.find((m) => m.shopExternalId == restaurant.shopExternalId);

        const { displayName, injectOrdersToS4D } = restaurant as ExtendedRestaurant;

        const klarnaErrors = validateKlarna(shopPaymentStatus.klarnaState, restaurant, merchant!);
        const adyenErrors = validateAdyen(shopPaymentStatus.adyenState, restaurant, merchant!, injectOrdersToS4D);

        const errors: ErrorGroup = {};

        if (adyenErrors.length > 0) {
          errors.Adyen = adyenErrors;
        }

        if (klarnaErrors.length > 0) {
          const filteredKlarnaErrors = klarnaErrors.filter((error) => {
            if (
              (ignoreKlarnaMissing && error === 'Klarna merchant missing') ||
              error.includes('Klarna merchant status unknown')
            )
              return;
            return error;
          });
          if (filteredKlarnaErrors.length > 0) {
            errors.Klarna = filteredKlarnaErrors;
          }
        }

        return {
          restaurantName: displayName,
          updatedAt: shopPaymentStatus.updatedAt,
          shopExternalId: shopPaymentStatus.shopExternalId,
          errors,
        };
      });

    return entries.filter((entry) => Object.keys(entry.errors).length > 0);
  }, [isLoading, merchants, restaurants, shopPaymentProviderStatus]);

  const validRestaurantsCount = useMemo(() => {
    if (!paymentExceptions || !restaurants) return null;
    return shopPaymentProviderStatus.length - paymentExceptions.length;
  }, [paymentExceptions, restaurants, shopPaymentProviderStatus.length]);

  const refetchRestaurant = async (shopExternalId: string) => {
    await refetchRestaurantPaymentStatus(shopExternalId);
    await queryClient.invalidateQueries([paymentsApiQueryKeys.allShopPaymentStatus]);
  };

  return { paymentExceptions, validRestaurantsCount, refetchRestaurant };
};
