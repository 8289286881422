import { TopBar } from '../ProductsPage';
import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import dayjs from '../../shared/utils/dayJsRelativeTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import { usePaymentExceptions } from './hooks/usePaymentExceptions';

type ErrorGroup = {
  [key: string]: string[];
};

type RestaurantCardProps = {
  shopExternalId: string;
  restaurantName: string;
  errors: ErrorGroup;
  updatedAt: string;
  refetchRestaurant: (shopExternalId: string) => void;
};

const RestaurantCard: React.FC<RestaurantCardProps> = ({
  shopExternalId,
  restaurantName,
  errors,
  updatedAt,
  refetchRestaurant,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <CardContent>
        <RestaurantDetails>
          <Typography variant="h3">
            {shopExternalId}
            <br />
            {restaurantName}
          </Typography>
          {Object.keys(errors).map((key) => {
            return (
              <ErrorGroup key={`error-group-${shopExternalId}-${key}`}>
                <b>{key}</b>
                <StyledUl>
                  {errors[key].map((error, index) => {
                    return <li key={`${shopExternalId}-${key}-${index}`}>{error}</li>;
                  })}
                </StyledUl>
              </ErrorGroup>
            );
          })}

          <LastUpdatedContainer>
            <RefreshButton onClick={() => refetchRestaurant(shopExternalId)}>
              <RefreshIcon />
            </RefreshButton>
            <UpdatedLabel>
              {t('Updated')} {dayjs().to(dayjs(updatedAt))}
            </UpdatedLabel>
          </LastUpdatedContainer>
        </RestaurantDetails>
      </CardContent>
    </StyledCard>
  );
};

const PaymentMethodsExceptionsPage = () => {
  const { t } = useTranslation();

  const { paymentExceptions, validRestaurantsCount, refetchRestaurant } = usePaymentExceptions();

  if (!paymentExceptions) {
    return null;
  }

  return (
    <>
      <TopBar>
        <Typography variant="h1">{t('Exceptions')}</Typography>
      </TopBar>
      <Typography variant="h2">
        {paymentExceptions.length} {t('restaurants has exceptions in payment methods')}
      </Typography>
      <span>
        ✅ {validRestaurantsCount} {t('restaurants ok')}
      </span>
      <RestaurantCards>
        {paymentExceptions.map((paymentException) => {
          return (
            <RestaurantCard
              refetchRestaurant={refetchRestaurant}
              updatedAt={paymentException.updatedAt}
              restaurantName={paymentException.restaurantName}
              shopExternalId={paymentException.shopExternalId}
              errors={paymentException.errors}
              key={`restaurant-card-${paymentException.shopExternalId}`}
            />
          );
        })}
      </RestaurantCards>
    </>
  );
};

const RestaurantCards = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  width: 25em;
`;

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpdatedLabel = styled.span`
  color: grey;
`;

const ErrorGroup = styled.div`
  margin-top: 16px;
`;

const LastUpdatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const RefreshButton = styled.button`
  padding: 0;
  border: 0;
  margin-right: 4px;
  cursor: pointer;
  background: none;
`;

export default PaymentMethodsExceptionsPage;
